import date from 'src/assets/icons/date.svg';
import email from 'src/assets/icons/email.svg';
import facebook from 'src/assets/icons/facebook.svg';
import gmail from 'src/assets/icons/gmail.svg';
import linkedin from 'src/assets/icons/linkedin.svg';
import profile from 'src/assets/icons/profile.svg';
import skype from 'src/assets/icons/skype.svg';
import twitter from 'src/assets/icons/twitter.svg';
import whatsapp from 'src/assets/icons/whatsapp.svg';
import HTML from 'src/assets/images/html-development-icon.svg';
import Eightstreet from 'src/assets/images/isha-client-8thstreet.svg';
import Antoine from 'src/assets/images/isha-client-antoine.svg';
import Ewigo from 'src/assets/images/isha-client-ewigo.svg';
import Handlangers from 'src/assets/images/isha-client-handlangers.svg';
import Invoiceq from 'src/assets/images/isha-client-invoiceq.svg';
import Playground from 'src/assets/images/isha-client-playground.svg';
import Samahi from 'src/assets/images/isha-client-samahi.svg';
import TenderJordan from 'src/assets/images/isha-client-tenderjordan.svg';
import Treestone from 'src/assets/images/isha-client-treestone.svg';
import Zestiot from 'src/assets/images/isha-client-zestiot.svg';
import logo from 'src/assets/images/logo.svg';
import ProfileOutline from 'src/assets/images/outline_profile.svg';
import WebApp from 'src/assets/images/web-app-development-icon.svg';
import Website from 'src/assets/images/website-development-icon.svg';

import Stacy from 'src/assets/images/8th-street-ventures-llc-stacy-stubblefield.png';
import Joshua from 'src/assets/images/cozy-collection-joshua-harder.png';
import Kastriot from 'src/assets/images/kastriot-sula.png';
import Sangeeta from 'src/assets/images/sangeeta.png';
import Hamza from 'src/assets/images/tender-jordan-hamza-andi.png';
import Vincent from 'src/assets/images/vincent-john.png';
import Amit from 'src/assets/images/zestiot-amit-shukhija.png';

import EnhancingTypographyWithWebkitFontSmoothingAntialiased from 'src/assets/images/Enhancing-Typography-with-webkit-font-smoothing-antialiased.jpg';
import HTMLMistakesWebDevelopersShouldAvoid from 'src/assets/images/HTML-Mistakes-Web-Developers-Should-Avoid.jpg';
import HowDoGetMoreLongTermClientsTipsForFreelancers from 'src/assets/images/How-do-get-more-long-term-clients-Tips-for-freelancers.jpg';
import HowToBecomeAFrontEndDeveloper from 'src/assets/images/How-to-become-a-front-end-developer.jpg';
import The10MostCommonMistakesWebDevelopersMake from 'src/assets/images/The-10-Most-Common-Mistakes-Web-Developers-Make.jpg';
import The7CSSMistakesWebDevelopersShouldAvoid from 'src/assets/images/The-7-CSS-Mistakes-Web-Developers-Should-Avoid.jpg';
import HowToCreateAProposalThatWinsJobs from 'src/assets/images/how-to-create-a-proposal-that-wins-jobs.jpg';

export const blogImages = {
  EnhancingTypographyWithWebkitFontSmoothingAntialiased:
    EnhancingTypographyWithWebkitFontSmoothingAntialiased,
  The7CSSMistakesWebDevelopersShouldAvoid:
    The7CSSMistakesWebDevelopersShouldAvoid,
  HowToBecomeAFrontEndDeveloper: HowToBecomeAFrontEndDeveloper,
  HTMLMistakesWebDevelopersShouldAvoid: HTMLMistakesWebDevelopersShouldAvoid,
  The10MostCommonMistakesWebDevelopersMake:
    The10MostCommonMistakesWebDevelopersMake,
  HowToCreateAProposalThatWinsJobs: HowToCreateAProposalThatWinsJobs,
  HowDoGetMoreLongTermClientsTipsForFreelancers:
    HowDoGetMoreLongTermClientsTipsForFreelancers
};

export const images = {
  logo: logo,
  amit: Amit,
  joshua: Joshua,
  vincent: Vincent,
  stacy: Stacy,
  hamza: Hamza,
  kastriot: Kastriot,
  sangeeta: Sangeeta,
  ProfileOutline: ProfileOutline
};

export const clientLogos = {
  treestone: Treestone,
  eightstreet: Eightstreet,
  antoine: Antoine,
  ewigo: Ewigo,
  handlangers: Handlangers,
  invoiceq: Invoiceq,
  playground: Playground,
  samahi: Samahi,
  zestiot: Zestiot,
  tenderJordan: TenderJordan
};

export const icons = {
  website: Website,
  webApp: WebApp,
  html: HTML,
  whatsapp: whatsapp,
  linkedin: linkedin,
  gmail: gmail,
  skype: skype,
  facebook: facebook,
  twitter: twitter,
  date: date,
  profile: profile,
  email: email
};
